import './Home.css'
import { LandingPage , Contactme , Techskills  , Aboutme, Languge } from '../../section/index'
function Home() {
  return (
    <>
    <LandingPage/>
    <Aboutme />
    <div className='skill'>
    <Techskills/>
    </div>
    <Languge/>
    <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
      <Contactme bgcolor='#3C3C3B' />
    </div>
    </>
  )
}

export default Home
