import './myProjects.css'

function Projects() {
  return (
    <div className='proj'>
      <h1 className='soon'>Soon !!! <span>As possible As i can !!</span></h1>
      
    </div>
  )
}

export default Projects
