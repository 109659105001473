import './Footer.css'
import { FaGithub , FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <>
    
    <p className='text-center footer mt-3'>
    Copyright © 2024 Balbali Saif. All rights reserved.
    <br/>Design: Saif <br/>
    🌐 Location: Tunisia 🇹🇳<br/>
    📧 Email: saifbalbali20@gmail.com<br/>
    🔗 LinkedIn: linkedin.com/in/saifbalbali<br/>
      <h4 className='text-center social'>
      <FaGithub />Balbali404<br/>
      <FaInstagram />Saifbalbali
      </h4>
    </p>
    
    </>
  )
}

export default Footer
