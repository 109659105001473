import './Contactme.css'
import { Form} from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';

function Contactme(props) {
  const [state, handleSubmit] = useForm("mayrorpj");
  if (state.succeeded) {
      return <Contactme/>;
  }
  
  return (
    < >
      
      <h2 className=' text-center title mt-5'   id='contact-me'>Contact Me</h2>
      <Form  className=' p-3 text-white form ' onSubmit={handleSubmit} action="https://formspree.io/f/mayrorpj" method="POST">
        <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
          
          <Form.Control type="email" placeholder="name@example.com" style={{ padding:'10px',width:'100%'}} className='email' name="email"/>
          <ValidationError prefix="Email" field="email"errors={state.errors}/>
        </Form.Group>
          
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
          
          <Form.Control type="text" placeholder="Your Names" style={{ padding:'10px',width:'100%'}} className='name' name="name"/>
          
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >
          
          <Form.Control as="textarea" rows={6} style={{padding:'10px'}} placeholder='Type your message here...' className='message' name='textarea'/>
          <ValidationError prefix="Message" field="message"errors={state.errors}/>
        </Form.Group>
        <Form.Group className="mb-3 fbtn" controlId="exampleForm.ControlButton" >
        <button  type="submit" disabled={state.submitting} className='mb-4 btn-sub'>Submit</button>
      </Form.Group>
      </Form>
      
      
      
     
      
    </>
  )
}

export default Contactme
