import './Header.css'
import { useEffect , useState } from 'react';

import logodark from '../../assets/logo-dark.png'
import logolight from '../../assets/logo-white.png'

import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const Header = () => {
    useEffect(() => {
      console.log(window.localStorage.getItem("selectedTheme"))
    },[])
  const [className, setClassName] = useState('');
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 56) {
        setClassName('fixed-top nv');
      } else {
        setClassName('');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  return (
    <Navbar expand="md" className={`navbar  ${className}`} >   
      <Container>
        <Navbar.Brand >
        <RouterLink to='/'><img src={window.localStorage.getItem("selectedTheme") === "dark" ? logodark : logolight} alt='' width='50' height='50'/></RouterLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbar-dark'  id='navtog' />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto ">
                <Nav.Link><ScrollLink  to="about-me" smooth={true} duration={500} className='nav-link head-cl'>About Me</ScrollLink></Nav.Link>
                <Nav.Link><ScrollLink to="my-skills" smooth={true} duration={500} className="nav-link head-cl">My Skills</ScrollLink></Nav.Link>
                <Nav.Link><ScrollLink to="contact-me" smooth={true} duration={500} className="nav-link head-cl">Contact Me</ScrollLink></Nav.Link>
                <Nav.Link><RouterLink to="/projects" className="nav-link head-cl">My Projects</RouterLink> </Nav.Link>      
                <Nav.Link href="https://store.balbalisaif.tech" target="_blank" rel="noopener noreferrer" >
                <a className="nav-link head-cl">
                  My Store
                </a>
              </Nav.Link>      
                <Nav.Link href="https://blog.balbalisaif.tech" target="_blank" rel="noopener noreferrer" >
                <a className="nav-link head-cl">
                  My Blog
                </a>
              </Nav.Link>      
            </Nav> 
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  )
}
export default Header
